import { defineStore } from 'pinia';
import { useNavyStore } from './navyStore';
import { ENavy, EPermissions } from '@/router';
import { getAuthKey, setAuthKey } from '../service/authKey';
import { loginUserByPassword, TBffResponseError, getMe } from '../service/userAuthService';


export type TUser = {
	loading: boolean;
	status: boolean;
	id: number;
	name: string;
	email: string;
	avatar: string;
	permissions: number[];
	authKey: string;

	// contacts
	phone: string;
	telegram: string;
	watsapp: string;

	// auth error
	errorMessage: string;
	errorCode: number;
	errorRequestId: string;
}

export const useUserStore = defineStore({
	id: 'user',
	state: (): TUser => ({
		loading: true,
		status: false,
		id: 0,
		name: '',
		email: '',
		avatar: '',
		permissions: [],
		authKey: getAuthKey(),

		// contacts
		phone: '',
		telegram:'',
		watsapp:'',

		// auth error
		errorMessage: '',
		errorCode: 0,
		errorRequestId: '',
	}),
	getters: {},
	actions: {
		clearErrors() {
			this.errorMessage = '';
			this.errorRequestId = '';
			this.errorCode = 0;
		},

		setErrors(message: string, requestId: string, code?: number) {
			this.errorMessage = message;
			this.errorRequestId = requestId;
			this.errorCode = code ?? 0;
		},

		logout() {
			setAuthKey('');
			window.location.href = ENavy.login;
		},

		matchAuth() {
			useNavyStore().setUserNavyList(this.permissions);
		},
	},
});

export const isUserAuth = async (): Promise<boolean> => {
	const userStore = useUserStore();

	const authKey = getAuthKey();
	if (!authKey) {
		setAuthKey('');
		userStore.loading = false;
		return false;
	}

	userStore.loading = true;

	return getMe()
		.then((userData)=>{
			userStore.status = true;
			userStore.email = userData.email;
			userStore.id = userData.id;
			userStore.name = [userData.first_name, userData.last_name].join(' ');
			userStore.phone = userData.phone;
			userStore.telegram = userData.telegram;
			userStore.watsapp = userData.watsapp;
			userStore.permissions = userData.permissions;

			return true;
		})
		.catch(()=>false)
		.finally(()=>{ userStore.loading = false })
}

export const loginUserByLoginPassword = async (identifier: string, password: string): Promise<boolean> => {
	const userStore = useUserStore();
	userStore.loading = true;
	userStore.clearErrors();

	return await loginUserByPassword(identifier, password)
		.then((userData)=>{
			userStore.status = true;
			userStore.clearErrors();
			userStore.id = userData.user.id;
			userStore.email = userData.user.email;
			userStore.authKey = userData.jwt;
			setAuthKey(userData.jwt);
			return true;
		})
		.catch((e: TBffResponseError)=>{
			userStore.status = false;
			userStore.setErrors(e.error, e.requestId, e.code);
			return false;
		})
		.finally(() => userStore.loading = false)
}

export const isUserHasPermission = (rule: number): boolean => {
	const store = useUserStore();
	return Boolean(~store.permissions.indexOf(rule));
}

export const isUserCanViewPermission = (): boolean => {
	return isUserHasPermission(EPermissions.viewPermission);
}

export const isUserCanEditPermission = (): boolean => {
	return isUserHasPermission(EPermissions.editPermission);
}

export const isUserCanViewUser = (): boolean => {
	return isUserHasPermission(EPermissions.viewUsers);
}

export const isUserCanEditUser = (): boolean => {
	return isUserHasPermission(EPermissions.editUsers);
}

export const isUserCanViewCmi = (): boolean => {
	return isUserHasPermission(EPermissions.viewCmi);
}

export const isUserCanEditCmi = (): boolean => {
	return isUserHasPermission(EPermissions.editCmi);
}

export const isUserCanEditPostCmi = (): boolean => {
	return isUserHasPermission(EPermissions.postCmi);
}
