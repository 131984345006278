import { createRouter, createWebHistory } from 'vue-router';
// components
import { LoginPage } from '@/modules/login';
import Cmi from '@/modules/cmi/CmiList.vue';
import NotFound from '@/components/NotFound.vue';
import Dashboard from '@/components/Dashboard.vue';
import PermissionsPage from '@/modules/userPermissions/PermissionsPage.vue';
import UserListPage from '@/modules/userPermissions/UserListPage.vue';
import UserPage from '@/modules/userPermissions/UserPage.vue';
import CmiDealViewerEditor from '@/modules/cmi/CmiDealViewerEditor.vue';
import PostPage from '@/modules/cmi/PostPage.vue';
import PostViewerEditorPage from '@/modules/cmi/PostViewerEditorPage.vue';
// user store
import {
	useUserStore,
	useNavyStore,
	isUserAuth,
} from '@/store';
// consts
import { defaultPageTitle } from './consts';
// dev / prod mode
const devMode = process?.env?.NODE_ENV === "development";
import DevKit from '@/components/kit/KIT.vue';


// роуты
export const routeNames = {
	// для KIT во время разработки
	...( devMode ? { kit: 'kit' } : {}),
	// роуты приложения
	dashboard: 'dashboard',
	// cmi
	cmi: 'cmi',
	cmiEditor: 'cmiEditor',
	cmiPost: 'cmiPost',
	cmiPostEditor: 'cmiPostEditor',

	admin: 'admin',
	userRulesAdministration: 'userRulesAdministration',
	userAdministration: 'userAdministration',
	userPage: 'userPage',
	userEditPage: 'userEditPage',
	login: 'login',
} as const;

// все ключи роутов
export type TRoutes = keyof typeof routeNames;

// список прав на действия
export const EPermissions = {
	// admin
	viewPermission: 10,
	editPermission: 11,
	viewUsers: 20,
	editUsers: 21, 

	// other
	viewCmi: 2,
	editCmi: 3,
	postCmi: 4,
} as const;

// права на роуты
export const routesPermissions: Record<TRoutes, number[]> = {
	kit: [],
	dashboard: [],

	// login
	login: [],

	// admin
	admin: [ EPermissions.viewPermission, EPermissions.editPermission, EPermissions.viewUsers, EPermissions.editUsers ],
	userRulesAdministration: [ EPermissions.viewPermission, EPermissions.editPermission ],
	userAdministration: [ EPermissions.viewUsers, EPermissions.editUsers ],
	userPage: [ EPermissions.viewUsers ],
	userEditPage: [ EPermissions.editUsers ],

	// cmi
	cmi: [ EPermissions.viewCmi, EPermissions.editCmi ],
	cmiEditor: [ EPermissions.viewCmi, EPermissions.editCmi ],
	cmiPost: [ EPermissions.postCmi, ],
	cmiPostEditor: [ EPermissions.postCmi, ],
};

// не юзаем enum, чтобы было можно юзать в шаблонах
export const ENavy: Record<TRoutes, string> = {
	kit: '/kit',
	dashboard: '/dashboard',

	// login
	login: '/login',
    
	// admin
	admin: '/admin', // admin of erp / администрирование erp
	userRulesAdministration: '/admin/rules', // admin of erp / администрирование erp
	userAdministration: '/admin/users', // admin of erp / администрирование erp
	userPage: '/user/:userId',
	userEditPage: '/edit-user/:userId',

	// cmi
	cmi: '/cmi/list/:status', // compulsory medical insurance - обязательное медицинское страхование
	cmiEditor: '/cmi/deal/:id', // просмотрщик и редактор заявки ОМС
	cmiPost: '/cmi/post-list',
	cmiPostEditor: '/cmi/post/:id',

	// reviews
	// reviews: '/reviews', // reviews on the forum - отзывы на форуме
};

export type TNavyRoute = typeof ENavy[keyof typeof ENavy];

export const router = createRouter({
	history: createWebHistory(),
	routes: [
		// Главная
		{
			// для удобства добавлен путь /
			path: '/',
			component: Dashboard,
			meta: {
				permissions: routesPermissions.dashboard 
			}
		},

		{
			name: routeNames.dashboard,
			path: ENavy.dashboard,
			component: Dashboard,
			meta: {
				permissions: routesPermissions.dashboard 
			}
		},

		// CMI
		{
			name: 'cmi',
			path: ENavy.cmi,
			component: Cmi,
			props: true,
			meta: {
				permissions: routesPermissions.cmi,
				pageTitle: 'OMC - ' + defaultPageTitle, 
			}
		},

		// CMI
		{
			name: 'cmi-editor',
			path: ENavy.cmiEditor,
			component: CmiDealViewerEditor,
			props: true,
			meta: {
				permissions: routesPermissions.cmiEditor,
				pageTitle: 'OMC - ' + defaultPageTitle, 
			}
		},
		// CMI
		{
			name: routeNames.cmiPost,
			path: ENavy.cmiPost,
			component: PostPage,
			// props: true,
			meta: {
				permissions: routesPermissions.cmiPost,
				pageTitle: 'OMC. Почтовые отправки - ' + defaultPageTitle, 
			}
		},
		// CMI
		{
			name: routeNames.cmiPostEditor,
			path: ENavy.cmiPostEditor,
			component: PostViewerEditorPage,
			// props: true,
			meta: {
				permissions: routesPermissions.cmiPostEditor,
				pageTitle: 'OMC. Почтовые отправки - ' + defaultPageTitle, 
			}
		},

		// // Отзывы
		// {
		//   name: routeNames.reviews,
		//   path: ENavy.reviews,
		//   component: Dashboard,
		//   meta: {
		//     permissions: routesPermissions.reviews 
		//   }
		// },

		// Администрирование
		{
			name: routeNames.admin,
			path: ENavy.admin,
			component: Dashboard,
			meta: {
				permissions: routesPermissions.admin,
				pageTitle: 'Администрирование - ' + defaultPageTitle, 
			}
		},
		{
			name: routeNames.userRulesAdministration,
			path: ENavy.userRulesAdministration,
			component: PermissionsPage,
			meta: {
				permissions: routesPermissions.userRulesAdministration,
				pageTitle: 'Управление правами - ' + defaultPageTitle, 
			}
		},
		{
			name: routeNames.userAdministration,
			path: ENavy.userAdministration,
			component: UserListPage,
			meta: {
				permissions: routesPermissions.userAdministration,
				pageTitle: 'Управление пользователями - ' + defaultPageTitle, 
			}
		},

		{
			name: routeNames.userPage,
			path: ENavy.userPage,
			component: UserPage,
			meta: {
				permissions: routesPermissions.userPage,
				pageTitle: 'Просмотр пользователя - ' + defaultPageTitle, 
			}
		},

		{
			name: routeNames.userEditPage,
			path: ENavy.userEditPage,
			component: UserPage,
			meta: {
				permissions: routesPermissions.userEditPage,
				pageTitle: 'Управление пользователем - ' + defaultPageTitle, 
			}
		},

		// kit, только для дев среды
		...( devMode ? [{
			name: routeNames.kit,
			path: ENavy.kit,
			component: DevKit,
		}]: []),

		// // login
		// {
		// 	// name: routeNames.login,
		// 	path: ENavy.login,
		// 	component: LoginPage
		// },

		// 404 - всегда последним элементом, vue пойдёт по роутам каскадом
		{
			path: '/:catchAll(.*)',
			component: NotFound
		}
	]
});


// закрываем мобильную навигацию
router.beforeResolve(()=>{
	const navyStore = useNavyStore();
	navyStore.closeMobileNavy();
});

// guard для проверки прав юзера на страницу
router.beforeResolve( async (to, from, next) => {
  
	// получаем права страницы
	const pathPermissions = to?.meta?.permissions || [];

	const userStore = useUserStore();
	// проверяем статус логина
	const isAuth = await isUserAuth();
	if (!isAuth) {
		if (to.path !== ENavy.login) {
			next(ENavy.login);
		}
		else {
			next();
		}
		return;
	}

	// если залогинен и страница логина - идём на dashboard
	if (isAuth && to.path === ENavy.login) {
		next('/');
		return;
	}

	// получаем права юзера
	userStore.matchAuth();

	if (Array.isArray(pathPermissions) && pathPermissions.length) {
		// если данные о юзере ещё грузятся, то ожидаем их
		const awaitUserPermissions = async (cb: (list: number[])=>void) => {
			if (!userStore.loading) {
				return cb(userStore.permissions);
			}
			setTimeout(()=>awaitUserPermissions(cb), 100);
		};

		const userPermissions: number[] = await new Promise(res=>{
			awaitUserPermissions(res);
		});

		// проверяем, есть ли хотя бы одно право на страницу
		const isSuccess = pathPermissions.length === 0 || [...userPermissions].filter(perm => {
			return ~pathPermissions.indexOf(perm)
		}).length;

		if (!isSuccess) {
			next(ENavy.login);
			return;
		}
	}

	next();
});

// route before resolve hook
// router.beforeResolve(async (to, from, next)=>{})

// pagetitle
router.afterEach((to) => {
	// устанавливаем title для страницы
	const pageTitle = to?.meta?.pageTitle || defaultPageTitle;
	(window as any).document.title = pageTitle;
});