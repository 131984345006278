import { 
	TCmiGetListResDto, 
	TCmiDealItemResDto, 
	TCmiDealItemUpdateReqDto, 
	TCmiPostedGetListResDto, 
	TCmiPostedGetItemResDto 
} from './types';
export { 
	TCmiGetListResDto, 
	TCmiDealItemResDto, 
	TCmiPostedGetListResDto, 
	TCmiPostedGetItemResDto 
} from './types';
import { get, put } from '@/service/bffService';
export { TBffResponseError } from '@/service/bffService';


export type TCmiSearchParams = {
	uid?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	antrag_id?: string;
}

export const getList = async (page: number, perPage: number, search?: TCmiSearchParams): Promise<TCmiGetListResDto> => {
	const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&')

	return get<TCmiGetListResDto>(`/api/formulars/cmi?perPage=${perPage}&page=${page}&${searchParams}`);
}

export const getItem = async (id: number): Promise<TCmiDealItemResDto> => {
	return get<TCmiDealItemResDto>('/api/formulars/cmi/' + id);
}

export const updateItem = async (id: number, data: TCmiDealItemUpdateReqDto): Promise<any> => {
	return put<any, TCmiDealItemUpdateReqDto>('/api/formulars/cmi/' + id, data);
}

export const sendTestTK = async (id: number): Promise<any> => {
	return get<any>('/api/formulars/cmk-tk/' + id);
}

export const sendProdTK = async (id: number): Promise<any> => {
	return get<any>('/api/formulars/cmk-tk-prod/' + id);
}


export const getPostedList = async (page: number, perPage: number, search?: Record<string, string>) => {
	const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

	return get<TCmiPostedGetListResDto>(`/api/formulars/cmi-posted?perPage=${perPage}&page=${page}&${searchParams}`);
}
export const getPostedItem = async (id: number) => {
	return get<TCmiPostedGetItemResDto>(`/api/formulars/cmi-posted/${id}`);
}

export const updatePostedItem = async (id: number, status: boolean, date?: string) => {
	return put<any, any>(`/api/formulars/cmi-posted/${id}`, {
		status,
		date,
	});
}