<script setup lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { useRoute } from 'vue-router'
// dev / prod mode
const devMode = process?.env?.NODE_ENV === "development";
// components
// store
import { useNavyStore } from '@/store';
// routes
import { routeNames, TRoutes, cmiStatuses } from "@/router";


defineComponent({ name: 'AppSidebar' });
const routes = ref<typeof routeNames>(routeNames);

// toogles
// чтобы добавить раскрывающееся меню в навигации
const liCanToggles = {
    cmi: 'cmi',
    admin: 'admin',
} as const;

type TLiCanToggle = typeof liCanToggles[keyof typeof liCanToggles];

// подготавливаем объект с открывающимися элементами навигации
const toggles = reactive<Record<TLiCanToggle, boolean>>(
    Object.fromEntries(
        Object.entries(liCanToggles)
            .map(item => [[item[1]], false])
    )
);

// store
const navyStore = useNavyStore();


// methods
const isNavyAllowed = (route: TRoutes) => {
    return Boolean(~navyStore.userNavyList.indexOf(route))
};

const toggle = (index: TLiCanToggle) => {
    toggles[index] = !toggles[index];
};

const isActive = (path: string | string[]) => {
    if (!Array.isArray(path)) {
        path = [path]
    }

    const route = useRoute();
    const routes = route.matched?.[0]?.name as string;
    return routes && ~path.indexOf(routes);
}
</script>

<template>
  <nav
    id="sidebar"
    :class="['sidebar sidebar-offcanvas', navyStore.mobileNavyOpen ? 'active' : ''].join(' ')"
  >
    <ul class="nav">
      <li class="nav-item nav-category unselectable">
        Main
      </li>

      <!-- Главная -->
      <li
        v-if="isNavyAllowed(routes.dashboard)"
        :class="['nav-item unselectable', isActive(routes.dashboard) ? 'active' : ''].join(' ')"
      >
        <router-link
          :to="{ name: routes.dashboard }"
          class="nav-link"
          active-class="active"
        >
          <span class="icon-bg"><i class="mdi mdi-cube menu-icon" /></span>
          <span class="menu-title">Dashboard</span>
        </router-link>
      </li>

      <!-- ОМС -->
      <li
        v-if="isNavyAllowed(routes.cmi)|| isNavyAllowed(routes.cmiPost)"
        :class="['nav-item unselectable', isActive(routes.cmi) ? 'active' : ''].join(' ')"
      >
        <a
          data-bs-toggle="collapse"
          aria-controls="page-layouts"
          :class="['nav-link', !toggles.cmi ? 'collapsed' : ''].join(' ')"
          :aria-expanded="toggles.cmi"
          @click="toggle(liCanToggles.cmi)"
        >
          <span class="icon-bg"><i class="mdi mdi-apps menu-icon" /></span>
          <span class="menu-title">ОМС</span>
          <i class="menu-arrow" />
        </a>
        <div
          id="page-layouts"
          :class="['collapse', toggles.cmi ? 'show' : ''].join(' ')"
        >
          <ul class="nav flex-column sub-menu">
            
            <li 
            v-if="isNavyAllowed(routes.cmi)" 
              class="nav-item"
            >
              <router-link
                :to="{ name: routes.cmi, params: { status: cmiStatuses.all } }"
                class="nav-link"
                active-class="active"
              >
                Список заявок
              </router-link>
            </li>

            <li
              v-if="isNavyAllowed(routes.cmiPost)" 
              class="nav-item"
            >
              <router-link
                :to="{ name: routes.cmiPost, params: { status: cmiStatuses.all  } }"
                class="nav-link"
                active-class="active"
              >
                Почтовые отправки
              </router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link
                :to="{ name: routes.cmi, params: { status: cmiStatuses.new } }"
                class="nav-link"
                active-class="active"
              >
                Новые
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: routes.cmi, params: { status: cmiStatuses.pending } }"
                class="nav-link"
                active-class="active"
              >
                Ожидание
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: routes.cmi, params: { status: cmiStatuses.complete } }"
                class="nav-link"
                active-class="active"
              >
                Готовы к отправке
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: routes.cmi, params: { status: cmiStatuses.done } }"
                class="nav-link"
                active-class="active"
              >
                Завершённые
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: routes.cmi, params: { status: cmiStatuses.error } }"
                class="nav-link"
                active-class="active"
              >
                Ошибочные
              </router-link>
            </li> -->
          </ul>
        </div>
      </li>

      <!-- Отзывы -->
      <li
        v-if="isNavyAllowed(routes.reviews)"
        :class="['nav-item unselectable', isActive(routes.reviews) ? 'active' : ''].join(' ')"
      >
        <router-link
          :to="{ name: routes.reviews }"
          class="nav-link"
          active-class="active"
        >
          <span class="icon-bg"><i class="mdi mdi-forum menu-icon" /></span>
          <span class="menu-title">Отзывы</span>
        </router-link>
      </li>

      <!-- Администрирование -->
      <li
        v-if="isNavyAllowed(routes.admin)"
        :class="['nav-item unselectable', isActive(routes.admin) ? 'active' : ''].join(' ')"
      >
        <a
          data-bs-toggle="collapse"
          aria-controls="page-layouts"
          :class="['nav-link', !toggles.admin ? 'collapsed' : ''].join(' ')"
          :aria-expanded="toggles.admin"
          @click="toggle(liCanToggles.admin)"
        >
          <span class="icon-bg"><i class="mdi mdi-apps menu-icon" /></span>
          <span class="menu-title">Admin</span>
          <i class="menu-arrow" />
        </a>
        <div
          id="page-layouts"
          :class="['collapse', toggles.admin ? 'show' : ''].join(' ')"
        >
          <ul class="nav flex-column sub-menu">
            <li
              v-if="isNavyAllowed(routes.userRulesAdministration)"
              class="nav-item"
            >
              <router-link
                :to="{ name: routes.userRulesAdministration }"
                class="nav-link"
                active-class="active"
              >
                Права
              </router-link>
            </li>
            <li
              v-if="isNavyAllowed(routes.userAdministration)"
              class="nav-item"
            >
              <router-link
                :to="{ name: routes.userAdministration }"
                class="nav-link"
                active-class="active"
              >
                Пользователи
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <!-- KIT. Только для дев режима -->
      <!-- После использования, закомментировать! -->
      <li
        v-if="devMode"
        class="nav-item unselectable"
      >
        <router-link
          class="nav-link"
          :to="{ name: routes.kit }"
        >
          <span class="icon-bg"><i class="mdi mdi-code-braces menu-icon" /></span>
          <span class="menu-title">Dev KIT</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
.sidebar {
  &.sidebar-offcanvas {
    z-index: 1000000;
}
}
</style>